import React, { Component, ErrorInfo } from "react"
import { createGlobalStyle } from "styled-components"

import normalize from "./normalize"
import style from "./style"
import { LangProvider } from "../components/l10n"
import { CartProvider } from "../components/cart"

const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${style}
`

interface State {
  error?: Error
}

class Root extends Component {
  state: State = {}

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error })
    if (window.Sentry) {
      Sentry.withScope((scope: any) => {
        scope.setExtras(errorInfo)
        Sentry.captureException(error)
      })
    }
  }

  render() {
    if (this.state.error) {
      return (
        <p style={{ color: "#C44", padding: 20 }}>
          An error happened.
          <br />
          Try reloading the page.
        </p>
      )
    }

    return (
      <LangProvider>
        <CartProvider>
          <GlobalStyles />
          {this.props.children}
        </CartProvider>
      </LangProvider>
    )
  }
}

export default Root
