interface IMessages {
  [key: string]: {
    en: string
    fr: string
  }
}

const MESSAGES: IMessages = {
  // home:
  why: {
    en: "Why slow?",
    fr: "Why slow?",
  },
  reason1: {
    en: "Slow because it's better to take it easy.",
    fr: "Slow because it's better to take it easy.",
  },
  reason2: {
    en: "Slow because things made with love and patience are the best.",
    fr: "Slow because things made with love and patience are the best.",
  },
  reason3: {
    en: "Slow because it's the responsible choice.",
    fr: "Slow because it's the responsible choice.",
  },
  // menu:
  all: {
    en: "All",
    fr: "Tout",
  },
  about: {
    en: "About",
    fr: "À propos",
  },
  // product:
  shipping: {
    en: "Shipping:",
    fr: "Livraison :",
  },
  international: {
    en: "International",
    fr: "International",
  },
  add: {
    en: "Add",
    fr: "Ajouter",
  },
  added: {
    en: "Added!",
    fr: "Ajouté !",
  },
  // cart:
  checkout: {
    en: "Checkout",
    fr: "Commander",
  },
  cart: {
    en: "Shopping cart",
    fr: "Panier",
  },
  item: {
    en: "Item",
    fr: "Article",
  },
  quantity: {
    en: "Quantity",
    fr: "Quantité",
  },
  price: {
    en: "Price",
    fr: "Prix",
  },
  subtotal: {
    en: "Subtotal",
    fr: "Sous-total",
  },
  delete: {
    en: "Do you really to remove this item from your cart?",
    fr: "Voulez-vous vraiment supprimer cet article de votre panier ?",
  },
  cart_empty: {
    en: "Your cart is empty",
    fr: "Votre panier est vide",
  },
  // cart widget:
  order_summary: {
    en: "Order summary",
    fr: "Votre commande",
  },
  total: {
    en: "Total",
    fr: "Total",
  },
  // checkout form:
  email: {
    en: "Your email address",
    fr: "Votre adresse e-mail",
  },
  name: {
    en: "Full name",
    fr: "Prénom + Nom",
  },
  address: {
    en: "Address",
    fr: "Adresse",
  },
  postcode: {
    en: "Postcode",
    fr: "Code postal",
  },
  city: {
    en: "City",
    fr: "Ville",
  },
  country: {
    en: "Country",
    fr: "Pays",
  },
  state: {
    en: "State",
    fr: "État",
  },
  tel: {
    en: "Phone number",
    fr: "Téléphone",
  },
  submit: {
    en: "Submit",
    fr: "Valider",
  },
  // payment:
  payment: {
    en: "Payment",
    fr: "Paiement",
  },
  pay: {
    en: "Pay",
    fr: "Payer",
  },
  card_num: {
    en: "Card number",
    fr: "Numéro de carte",
  },
  card_exp: {
    en: "Expiration",
    fr: "Expiration",
  },
  card_cvc: {
    en: "CVC",
    fr: "Code de sécurité",
  },
  security: {
    en: "Payment securely processed by Stripe. We do not have access to your card number",
    fr: "Paiement sécurisé par Stripe. Nous n’avons pas accès à votre numéro de carte.",
  },
  // confirmation:
  thanks: {
    en: "Thank you!",
    fr: "Merci !",
  },
  thanks_text: {
    en: "Thank you for your order, we are on it!",
    fr: "Merci pour votre commande, on est dessus !",
  },
  confirmation_email: {
    en: "You should receive a confirmation email.",
    fr: "Vous devriez recevoir un email de confirmation.",
  },
  // 404:
  not_found: {
    en: "Page not found",
    fr: "Page introuvable",
  },
  not_found_text: {
    en: "This page does not exist, sorry!",
    fr: "Cette page n’existe pas, désolé !",
  },
  // footer & legal:
  footer: {
    en: "Contact & legal",
    fr: "Contact et mentions légales",
  },
  contact: {
    en: "Contact",
    fr: "Contact",
  },
  contact_text: {
    en: "Just drop me an email:",
    fr: "Envoyez-moi un email :",
  },
}

export default MESSAGES
