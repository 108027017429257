// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-tsx": () => import("/opt/build/repo/src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-product-tsx": () => import("/opt/build/repo/src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("/opt/build/repo/src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("/opt/build/repo/src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("/opt/build/repo/src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-pay-tsx": () => import("/opt/build/repo/src/pages/pay.tsx" /* webpackChunkName: "component---src-pages-pay-tsx" */),
  "component---src-pages-shop-tsx": () => import("/opt/build/repo/src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-thanks-tsx": () => import("/opt/build/repo/src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

