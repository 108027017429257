/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import FontLoader from "webfontloader"

import Root from "./src/layout/root"

export const onClientEntry = () => {
  // load the Sentry SDK asynchronously
  // advantage: lighter initial JS (faster initial page load)
  // drawback: won't capture potential initial JS errors
  import("@sentry/browser").then((Sentry) => {
    Sentry.init({
      dsn: process.env.GATSBY_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      enabled: process.env.NODE_ENV === "production",
      release: process.env.GATSBY_RELEASE,
    })
    Sentry.configureScope((scope) => {
      scope.setExtra("deploy_url", process.env.GATSBY_DEPLOY_URL)
      scope.setExtra("deploy_date", process.env.GATSBY_DEPLOY_DATE)
    })
    window.Sentry = Sentry
  })

  // TODO: replace with https://fonts.googleapis.com/css?family=Playfair+Display|Questrial&display=swap
  FontLoader.load({
    google: {
      families: ["Playfair Display:400,700", "Questrial"],
    },
  })

  const polyfills = []

  if (typeof IntersectionObserver === "undefined") {
    // IE will have to wait for this polyfill to be loaded first, because it's needed for gatsby-image
    polyfills.push(import("intersection-observer"))
  }
  if (typeof fetch === "undefined") {
    polyfills.push(import("whatwg-fetch"))
  }
  return Promise.all(polyfills)
}

export const wrapPageElement = ({ element }) => <Root>{element}</Root>
