import { css } from "styled-components"

export default css`
  body {
    font-family: sans-serif;
    letter-spacing: 1px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  body.noscroll {
    width: 100%;
    position: fixed;
  }
  .wf-active body {
    font-family: "Questrial", sans-serif;
  }
  h1,
  h2,
  h3 {
    font-weight: normal;
  }
  h1,
  h2 {
    font-family: serif;
    letter-spacing: 2px;
  }
  .wf-active h1,
  .wf-active h2 {
    font-family: "Playfair Display", serif;
  }
  h2,
  h3 {
    font-size: 19px;
  }
  a {
    text-decoration: none;
    color: initial;
  }
  ul {
    padding-inline-start: 16px;
  }
  svg {
    vertical-align: middle;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
`
