import React, { useContext, ReactType, Fragment, useState, useEffect } from "react"

import MESSAGES from "../helpers/messages"

interface ILangContext {
  lang: string
  setLang: (lang: string) => void
}

const DEFAULT_LANG = "en"

const getUserLang = () => (navigator.language.indexOf("fr") === 0 ? "fr" : DEFAULT_LANG)

export const LangContext = React.createContext<ILangContext>(null)

export const LangProvider = ({ children }) => {
  const [lang, setLang] = useState<string>(DEFAULT_LANG)

  useEffect(() => {
    setLang(getUserLang()) // because it can't be done server side
  }, [])

  return <LangContext.Provider value={{ lang, setLang }}>{children}</LangContext.Provider>
}

interface MessageProps {
  id: string
  Tag?: ReactType
}

export const translate = (id: string, lang: string) => {
  const message = MESSAGES[id]
  return (message && (message[lang] || message.en)) || id
}

export const Message = ({ id, Tag = Fragment }: MessageProps) => {
  const { lang } = useContext(LangContext)
  return <Tag>{translate(id, lang)}</Tag>
}

interface PriceProps {
  amount: number
  Tag?: ReactType
}

export const Money = ({ amount, Tag = Fragment }: PriceProps) => {
  const { lang } = useContext(LangContext)
  const price = Number.isInteger(amount) ? String(amount) : amount.toFixed(2)
  if (lang === "fr") {
    return <Tag>{price.replace(".", ",")} €</Tag>
  }
  return <Tag>€{price}</Tag>
}
