import { css } from "styled-components"

// https://github.com/necolas/normalize.css/blob/master/normalize.css

export default css`
  html {
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
  main {
    display: block;
  }
  a {
    background-color: transparent;
  }
  img {
    border-style: none;
  }
  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-transform: none;
    -webkit-appearance: button;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
  }
  iframe {
    border: none;
  }
`
