import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { LangContext, translate } from "./l10n"

interface ICartProduct {
  slug: string
  quantity: number
}
interface ICart {
  products: ICartProduct[]
  hydrated: boolean
}
interface ICartContext {
  cart: ICart
  add: (slug: string, quantity: number) => void
  sub: (slug: string, quantity: number) => void
  remove: (slug: string) => void
  clear: () => void
  total: () => number
}

const getStoredCart = (): ICart => {
  const cart: ICart = {
    products: [],
    hydrated: true,
  }
  if (window.localStorage) {
    const item = localStorage.getItem("cart")
    if (item) {
      try {
        const data = JSON.parse(item)
        if (data && data.products) {
          // TODO: verify data integrity (slugs)
          cart.products = data.products
        }
      } catch (error) {
        console.warn("localStorage.cart is corrupted, deleting") // tslint:disable-line
        localStorage.removeItem("cart")
      }
    }
  }
  return cart
}
const setStoredCart = (cart: ICart) => {
  if (window.localStorage) {
    localStorage.setItem("cart", JSON.stringify(cart))
  }
}

export const CartContext = React.createContext<ICartContext>(null)

export const CartProvider = ({ children }) => {
  const { lang } = useContext(LangContext)
  const [cart, setCart] = useState<ICart>({
    products: [],
    hydrated: false,
  })

  const {
    products: { edges },
  } = useStaticQuery(graphql`
    {
      products: allContentfulProduct {
        edges {
          node {
            slug
            price
          }
        }
      }
    }
  `)

  const products: Array<{ slug: string; price: number }> = edges.map(({ node }) => node)

  const storeCart = (updatedCart: ICart) => {
    setCart({ ...updatedCart })
    setStoredCart(updatedCart)
  }

  const add = (slug: string, quantity: number) => {
    const existing = cart.products.find((product) => product.slug === slug)
    if (existing) {
      existing.quantity += quantity
    } else {
      cart.products.push({ slug, quantity })
    }
    storeCart(cart)
  }
  const sub = (slug: string, quantity: number) => {
    const existing = cart.products.find((product) => product.slug === slug)
    if (quantity >= existing.quantity) {
      remove(slug)
    } else {
      existing.quantity -= quantity
      storeCart(cart)
    }
  }
  const remove = (slug: string) => {
    if (confirm(translate("delete", lang))) {
      cart.products = cart.products.filter((product) => product.slug !== slug)
      storeCart(cart)
    }
  }
  const clear = () => {
    cart.products = []
    storeCart(cart)
  }
  const total = () =>
    cart.products.reduce((prev, { slug, quantity }) => {
      const { price } = products.find((product) => product.slug === slug)
      return prev + price * quantity
    }, 0)

  useEffect(() => {
    setCart(getStoredCart()) // because it can't be done server side
  }, [])

  return <CartContext.Provider value={{ cart, add, sub, remove, clear, total }}>{children}</CartContext.Provider>
}
